import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { RegistrationManager } from '@insurance-broker/shared/models/registration-manager';
import { User } from '@core/models/user';
import { map } from 'rxjs/operators';
import { omit } from 'lodash';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {}

  public getRsUsers(roleGroup: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/rs-number?roleGroup=${roleGroup}`)
      .pipe(
        map(users => users.map(user => omit(user, 'email')))
      );
  }

  public getFsmaNumber(rsNumber: string): Observable<string> {
    return this.http.get<string>(`${environment.apiUrl}/rs-number/${rsNumber}/fsmaNumber`);
  }

  public getRegistrationManagers(rsNumber: string): Observable<RegistrationManager[]> {
    return this.http.get<RegistrationManager[]>(`${environment.apiUrl}/rs-number/${rsNumber}/registration-managers`);
  }

}
