import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { CurrentUser } from '@core/models/user';
import { selectCurrentRsUser } from '@core/state/user/user.selectors';
import { CoreState } from '@core/state/core.reducer';

@Injectable({
  providedIn: 'root',
})
export class RsNumberGuard  {

  public constructor(
    private readonly router: Router,
    private readonly store: Store<CoreState>
  ) {}

  public canActivate(): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectCurrentRsUser).pipe(

      map((currentRsUser: CurrentUser | null): boolean => {
        if (!currentRsUser) {
          this.router.navigate(['rs-number']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
