import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ListItem, ListOption } from '@shared/models/list-options';


@Component({
  selector: 'ui-renta-additive-list',
  templateUrl: './additive-list.component.html',
  styleUrls: ['./additive-list.component.scss'],
})
export class AdditiveListComponent implements OnChanges {

  @Input() public selectionTitle: string = 'selectionTitle';
  @Input() public listTitle: string = 'listTitle';
  @Input() public listSubtitle: string = 'listSubtitle';
  @Input() public listOptions: ListOption[] = [];
  @Input() public listItems: ListItem[] = [];

  @Output('onAdd') public onAdd = new EventEmitter();
  @Output('onRemove') public onRemove = new EventEmitter();

  public selectedValue: number | null = null;
  public disableActions: boolean = true;
  public filteredListOptions?: ListOption[] = [];
  public sortedListItems?: ListItem[] = undefined;

  private static alphabeticalSortByName(element1: { name: string; }, element2: { name: string; }): number {
    const bName = element1.name.toUpperCase();
    const aName = element2.name.toUpperCase();
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  }

  public ngOnChanges(): void {
    if (!this.listOptions || !this.listItems) {
      return;
    }

    this.filterAndSort();
    this.setSelectToFirstValue();
    this.disableActions = false;
  }

  public addToList(itemId: number | null): void {
    if (itemId === null || this.disableActions) {
      return;
    }
    this.disableActions = true;
    const item = this.filteredListOptions!.find((listOption) => listOption.id == itemId);
    this.onAdd.emit(item);
  }

  public removeFromList(item: ListItem): void {
    if (this.disableActions) {
      return;
    }
    this.disableActions = true;
    this.onRemove.emit(item);
  }

  private filterAndSort(): void {
    this.sortedListItems = [...this.listItems].sort(AdditiveListComponent.alphabeticalSortByName);
    this.filteredListOptions = [...this.listOptions]
      .sort(AdditiveListComponent.alphabeticalSortByName)
      .filter((option) => !this.sortedListItems!.find((item) => item.id == option.id));
  }

  private setSelectToFirstValue(): void {
    this.selectedValue = this.filteredListOptions!.length && this.filteredListOptions![0].id;
  }
}
