import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  loadCurrentRsUserFsmaNumberFailure, loadCurrentRsUserFsmaNumberSuccess, loadCurrentRsUserRegistrationManagers, loadCurrentRsUserRegistrationManagersFailure,
  loadCurrentRsUserRegistrationManagersSuccess, updateCurrentRsUser
} from '@core/state/user/user.actions';
import { UserService } from '@core/services/user/user.service';
import { mergeMap, of } from 'rxjs';

@Injectable()
export class UserEffects {

  constructor(
    private readonly userService: UserService,
    private readonly actions$: Actions
  ) {
  }

  public loadCurrentRsUserFsmaNumber = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCurrentRsUser),
      mergeMap(({ user }) => this.userService.getFsmaNumber(user.loginString)
        .pipe(
          map((fsmaNumber) => loadCurrentRsUserFsmaNumberSuccess({ fsmaNumber })),
          catchError( (error) => of(loadCurrentRsUserFsmaNumberFailure({ error })))
        )),
    )
  });

  public loadCurrentRsUserRegistrationManagers = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCurrentRsUserRegistrationManagers),
      switchMap(({ rsNumber }) => this.userService.getRegistrationManagers(rsNumber)
        .pipe(
          map(registrationManagers => loadCurrentRsUserRegistrationManagersSuccess({ registrationManagers })),
          catchError( (error) => of(loadCurrentRsUserRegistrationManagersFailure( { error })))
        ))
    )
  });

}
