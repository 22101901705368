import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from '@core/services/material/custom-mat-paginator-intl-service';
import { UserService } from '@core/services/user/user.service';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

export const coreServiceProviders = [
  CookieService,
  JwtHelperService,
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  UserService,
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntlService
  }
];
