import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { RsLoaderComponent } from '@rs-shared-lib/components/rs-loader/components/rs-loader.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    CoreModule,
    AppRoutingModule,
    RsLoaderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
