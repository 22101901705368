import { updateDisplayTopBarLinkSwitchRsUser } from './app.actions';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

export interface AppState {
  displayTopBarLinkSwitchRsUser: boolean;
}

export const initialAppState: AppState = {
  displayTopBarLinkSwitchRsUser: false
};

export const appReducer = createReducer(
  initialAppState,

  on(updateDisplayTopBarLinkSwitchRsUser, (state, { isTopBarLinkSwitchRsUserDisplayed }) => {
    return produce(state, (draft) => {
      draft.displayTopBarLinkSwitchRsUser = isTopBarLinkSwitchRsUserDisplayed;
    });
  }),
);
