import { Action, ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import { userReducer, UserState } from '@core/state/user/user.reducer';
import { appReducer, AppState } from '@core/state/app/app.reducer';
import { logout } from './user/user.actions';
import { localStorageSync } from 'ngrx-store-localstorage';
import {
  rsLocalStorageMergeReducer
} from '@rs-shared-lib/3rd-party-libraries/ngrx-store-localstorage/rs-local-storage-merge-reducer';

export interface CoreState {
  app: AppState,
  user: UserState
}

export const coreReducer: ActionReducerMap<CoreState> = {
  app: appReducer as ActionReducer<AppState>,
  user: userReducer as ActionReducer<UserState>,
};

export function clearState(reducer: ActionReducer<CoreState>): ActionReducer<CoreState> {
  return (state: CoreState | undefined, action: Action): CoreState => {
    if ( action != null && action.type === logout.type) {
      return reducer( undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

const persistentReducerKeys = ['app', 'user'];

export function localStorageSyncReducer(reducer: ActionReducer<CoreState>): ActionReducer<CoreState> {
  return localStorageSync({
    keys: persistentReducerKeys,
    storageKeySerializer: (key): string => 'persistent_' + key,
    rehydrate: true,
    mergeReducer: rsLocalStorageMergeReducer
  })(reducer);
}

export const metaReducers: MetaReducer<CoreState>[] = [localStorageSyncReducer, clearState];
