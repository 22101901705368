/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RsNumberGuard } from '@core/guards/rs-number.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./rs-number-selection/rs-number-selection.module').then(m => m.RsNumberSelectionModule),
    pathMatch: 'full'
  },
  {
    path: 'insurance-broker',
    loadChildren: () => import('./insurance-broker/insurance-broker.module').then(m => m.InsuranceBrokerModule),
    canActivate: [RsNumberGuard]
  },
  {
    path: 'insurance-company',
    loadChildren: () => import('./insurance-company/insurance-company.module').then(m => m.InsuranceCompanyModule),
    canActivate: [RsNumberGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
