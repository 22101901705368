import {
  loadCurrentRsUserFsmaNumberSuccess,
  loadCurrentRsUserRegistrationManagersSuccess,
  logout,
  updateCurrentRsUser
} from './user.actions';
import { createReducer, on } from '@ngrx/store';
import { RegistrationManager } from '@insurance-broker/shared/models/registration-manager';
import { CurrentUser } from '@core/models/user';
import { produce } from 'immer';

export interface UserState {
	currentRsUser: CurrentUser | null;
	currentRsUserFsmaNumber: string | null;
	currentRsUserRegistrationManagers: RegistrationManager[];
}

export const initialUserState: UserState = {
  currentRsUser: null,
  currentRsUserFsmaNumber: null,
  currentRsUserRegistrationManagers: []
};

export const userReducer = createReducer(
  initialUserState,
  on(loadCurrentRsUserFsmaNumberSuccess, (state, { fsmaNumber }) => {
    return produce(state, (draft) => {
      draft.currentRsUserFsmaNumber = fsmaNumber;
    });
  }),
  on(loadCurrentRsUserRegistrationManagersSuccess, (state, { registrationManagers }) => {
    return produce(state, (draft) => {
      draft.currentRsUserRegistrationManagers = registrationManagers;
    });
  }),
  on(updateCurrentRsUser, (state, { user }) => {
    return produce(state, (draft) => {
      draft.currentRsUser = user;
    });
  }),
  on(logout, () => {
    return {
      currentRsUser: null,
      currentRsUserFsmaNumber: null,
      currentRsUserRegistrationManagers: []
    } as UserState;
  })
);
