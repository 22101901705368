import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '@core/state/user/user.reducer';
import { UserFull } from '@core/models/user-full';

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectCurrentRsUser = createSelector(selectUserState, (state) => state.currentRsUser);
export const selectCurrentRsUserFsmaNumber = createSelector(selectUserState, (state) => state.currentRsUserFsmaNumber);
export const selectCurrentRsUserRegistrationManagers = createSelector(selectUserState, (state) => state.currentRsUserRegistrationManagers);

export const selectCurrentRsUserFull = createSelector(
  selectCurrentRsUser,
  selectCurrentRsUserFsmaNumber,
  selectCurrentRsUserRegistrationManagers,
  (user, fsmaNumber, registrationManagers) => {
    return {
      ...user,
      fsmaNumber: fsmaNumber,
      registrationManagers: registrationManagers
    } as UserFull
  }
);
