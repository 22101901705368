import { createAction, props } from '@ngrx/store';
import { CurrentUser } from '@core/models/user';
import { RsBackEndError } from '@lib/rs-ngx';
import { RegistrationManager } from '@insurance-broker/shared/models/registration-manager';

export const loadCurrentRsUserFsmaNumber = createAction(
  '[User] Load Current Rs User Fsma Number',
  props<{ rsNumber: string }>()
);

export const loadCurrentRsUserFsmaNumberSuccess = createAction(
  '[User] Load Current Rs User Fsma Number Success',
  props<{ fsmaNumber: string }>()
);

export const loadCurrentRsUserFsmaNumberFailure = createAction(
  '[User] Load Current Rs User Fsma Number Failure',
  props<{ error: RsBackEndError }>()
);

export const loadCurrentRsUserRegistrationManagers = createAction(
  '[User] Load Current Rs User Registration Managers',
  props<{ rsNumber: string }>()
);

export const loadCurrentRsUserRegistrationManagersSuccess = createAction(
  '[User] Load Current Rs User Registration Managers Success',
  props<{ registrationManagers: RegistrationManager[] }>()
);

export const loadCurrentRsUserRegistrationManagersFailure = createAction(
  '[User] Load Current Rs User Registration Managers Failure',
  props<{ error: RsBackEndError }>()
);

export const updateCurrentRsUser = createAction(
  '[User] Update Current Rs User',
  props<{ user: CurrentUser }>()
);

export const logout = createAction('[User] Logout');
