import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { httpInterceptorProviders } from '@app/core/http-interceptors';
import { coreServiceProviders } from '@core/services';
import { errorHandlerFactory } from '@core/error-handlers/error-handler-factory';
import { coreReducer, metaReducers } from '@core/state/core.reducer';
import { UserEffects } from '@core/state/user/user.effects';
import { EffectsModule } from '@ngrx/effects';
import { registerLocaleData } from '@angular/common';
import { RsCoreModule, UserInfoCookieAppName } from '@lib/rs-ngx';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);


@NgModule({ imports: [
  BrowserAnimationsModule,
  BrowserModule,
  RsCoreModule.forRoot({
    appName: UserInfoCookieAppName.DIVC,
    environment
  }),
  StoreModule.forRoot(coreReducer, { metaReducers }),
  EffectsModule.forRoot([UserEffects]),
  StoreDevtoolsModule.instrument({ maxAge: 25,
    logOnly: environment.production,
    connectInZone: true })
],
providers: [
  provideEnvironmentNgxMask(),
  { provide: ErrorHandler, useFactory: errorHandlerFactory },
  httpInterceptorProviders,
  coreServiceProviders,
  provideHttpClient(withInterceptorsFromDi())
] })
export class CoreModule {
  /**
     * Ensures that the CoreModule can only be loaded once.
     *
     * https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
     *
     * @param parentModule
     */
  public constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it only once!');
    }
  }
}
