<rs-top-bar-menu
  (onLogout)="clearUserOnLogout()"
  (onRouteChange)="onRouteChange($event)"
  [application]="title"
  [cdnUrl]="topBarCdn"
  [dummy]="topBarEnvironment==='dev'"
  [environment]="topBarEnvironment"
  [footer]="false"
  [menuItems]="menuItems"
>
</rs-top-bar-menu>

<rs-loader></rs-loader>

<router-outlet [attr.data-app-version]='appVersion'></router-outlet>

<rs-footer
  [cookiesKey]="'FOOTER.COOKIES'"
  [disclaimerKey]="'FOOTER.DISCLAIMER'"
  [needHelpKey]="'FOOTER.NEED_HELP'"
>
</rs-footer>