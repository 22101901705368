import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomMaterialModule } from '@shared/material/custom-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RentaNgxLibModule } from '@lib/rs-ngx';
import { AdditiveListComponent } from '@shared/components/additive-list.component';

/** @deprecated Usage will change, please read description below!!!
 *
 * @description What!!! How should we use it now ???
 *
 * - Will be used only to import/export real shared modules like CommonModule, TranslateModule.forChild (Those will be shared only in rentaNgxModule)
 * - For other modules like Material one's, please ONLY import the ones you need where you need them!
 * - For shared components, please create standalone ones and import it only where needed
 * - Same for shared directives, and pipes, please create standalone ones and import it only where needed
 **/
@NgModule({
  declarations: [AdditiveListComponent],
  imports: [
    CommonModule,
    RentaNgxLibModule,
    FormsModule,
    CustomMaterialModule,
  ],
  exports: [
    CommonModule,
    RentaNgxLibModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    AdditiveListComponent
  ]
})
export class SharedModule {
}
