import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@environments/environment';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://cea436107c304b62835d9bdec2a564cb@o268277.ingest.sentry.io/5219333',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.configureScope((scope): void => {
  scope.setTag('app.environment', environment.environment);
});

Sentry.configureScope((scope): void => {
  scope.setTag('app.name', 'DIVC');
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  public handleError(error: { originalError: unknown; }): void {
    Sentry.captureException(error.originalError || error);
  }
}
